"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.extractMessageFromFailedTx = exports.extractGanacheErrorMessage = exports.isGanacheError = exports.isTxError = exports.checkMinBalance = void 0;
function checkMinBalance(provider, address, minBalance) {
    return __awaiter(this, void 0, void 0, function* () {
        const balance = yield provider.getBalance(address);
        if (balance < minBalance) {
            throw new Error('Not enough funds');
        }
    });
}
exports.checkMinBalance = checkMinBalance;
function isTxError(error) {
    const txError = error;
    return Boolean(txError.error && typeof txError.code === 'string' && typeof txError.reason === 'string');
}
exports.isTxError = isTxError;
function isGanacheError(error) {
    var _a, _b;
    const ganacheError = error;
    return Boolean(ganacheError.id &&
        typeof ((_a = ganacheError.error) === null || _a === void 0 ? void 0 : _a.message) === 'string' &&
        ((_b = ganacheError.error) === null || _b === void 0 ? void 0 : _b.message.indexOf('revert')) > 0);
}
exports.isGanacheError = isGanacheError;
function extractGanacheErrorMessage(error) {
    const { message } = error.error;
    return message.substring(message.indexOf('revert') + 7);
}
exports.extractGanacheErrorMessage = extractGanacheErrorMessage;
function extractMessageFromFailedTx(exception) {
    var _a, _b, _c;
    let errorBody;
    if ((_a = exception === null || exception === void 0 ? void 0 : exception.error) === null || _a === void 0 ? void 0 : _a.body) {
        errorBody = JSON.parse(exception.error.body);
        if (isGanacheError(errorBody)) {
            return extractGanacheErrorMessage(errorBody);
        }
    }
    else if ((_b = exception === null || exception === void 0 ? void 0 : exception.error) === null || _b === void 0 ? void 0 : _b.message) {
        errorBody = (_c = exception === null || exception === void 0 ? void 0 : exception.error) === null || _c === void 0 ? void 0 : _c.message;
    }
    else {
        errorBody = 'Unknown error: ' + JSON.stringify(exception);
    }
    throw errorBody;
}
exports.extractMessageFromFailedTx = extractMessageFromFailedTx;
