/*
 * SPDX-FileCopyrightText: 2021 The HedgeDoc developers (see AUTHORS file)
 *
 * SPDX-License-Identifier: AGPL-3.0-only
 */

export enum ForkAwesomeIcons {
  '500px' = '500px',
  'activitypub' = 'activitypub',
  'address-book-o' = 'address-book-o',
  'address-book' = 'address-book',
  'address-card-o' = 'address-card-o',
  'address-card' = 'address-card',
  'adjust' = 'adjust',
  'adn' = 'adn',
  'align-center' = 'align-center',
  'align-justify' = 'align-justify',
  'align-left' = 'align-left',
  'align-right' = 'align-right',
  'amazon' = 'amazon',
  'ambulance' = 'ambulance',
  'american-sign-language-interpreting' = 'american-sign-language-interpreting',
  'anchor' = 'anchor',
  'android' = 'android',
  'angellist' = 'angellist',
  'angle-double-down' = 'angle-double-down',
  'angle-double-left' = 'angle-double-left',
  'angle-double-right' = 'angle-double-right',
  'angle-double-up' = 'angle-double-up',
  'angle-down' = 'angle-down',
  'angle-left' = 'angle-left',
  'angle-right' = 'angle-right',
  'angle-up' = 'angle-up',
  'apple' = 'apple',
  'archive-org' = 'archive-org',
  'archive' = 'archive',
  'archlinux' = 'archlinux',
  'area-chart' = 'area-chart',
  'arrow-circle-down' = 'arrow-circle-down',
  'arrow-circle-left' = 'arrow-circle-left',
  'arrow-circle-o-down' = 'arrow-circle-o-down',
  'arrow-circle-o-left' = 'arrow-circle-o-left',
  'arrow-circle-o-right' = 'arrow-circle-o-right',
  'arrow-circle-o-up' = 'arrow-circle-o-up',
  'arrow-circle-right' = 'arrow-circle-right',
  'arrow-circle-up' = 'arrow-circle-up',
  'arrow-down' = 'arrow-down',
  'arrow-left' = 'arrow-left',
  'arrow-right' = 'arrow-right',
  'arrows-alt' = 'arrows-alt',
  'arrows-h' = 'arrows-h',
  'arrows' = 'arrows',
  'arrows-v' = 'arrows-v',
  'arrow-up' = 'arrow-up',
  'artstation' = 'artstation',
  'assistive-listening-systems' = 'assistive-listening-systems',
  'asterisk' = 'asterisk',
  'at' = 'at',
  'att' = 'att',
  'audio-description' = 'audio-description',
  'backward' = 'backward',
  'balance-scale' = 'balance-scale',
  'bandcamp' = 'bandcamp',
  'ban' = 'ban',
  'bar-chart' = 'bar-chart',
  'barcode' = 'barcode',
  'bars' = 'bars',
  'bath' = 'bath',
  'battery-empty' = 'battery-empty',
  'battery-full' = 'battery-full',
  'battery-half' = 'battery-half',
  'battery-quarter' = 'battery-quarter',
  'battery-three-quarters' = 'battery-three-quarters',
  'bed' = 'bed',
  'beer' = 'beer',
  'behance-square' = 'behance-square',
  'behance' = 'behance',
  'bell-o' = 'bell-o',
  'bell-rigning-o' = 'bell-rigning-o',
  'bell-ringing' = 'bell-ringing',
  'bell-slash-o' = 'bell-slash-o',
  'bell-slash' = 'bell-slash',
  'bell' = 'bell',
  'bicycle' = 'bicycle',
  'binoculars' = 'binoculars',
  'biometric' = 'biometric',
  'birthday-cake' = 'birthday-cake',
  'bitbucket-square' = 'bitbucket-square',
  'bitbucket' = 'bitbucket',
  'black-tie' = 'black-tie',
  'blind' = 'blind',
  'bluetooth-b' = 'bluetooth-b',
  'bluetooth' = 'bluetooth',
  'bold' = 'bold',
  'bolt' = 'bolt',
  'bomb' = 'bomb',
  'bookmark-o' = 'bookmark-o',
  'bookmark' = 'bookmark',
  'book' = 'book',
  'bootstrap' = 'bootstrap',
  'braille' = 'braille',
  'briefcase' = 'briefcase',
  'btc' = 'btc',
  'bug' = 'bug',
  'building-o' = 'building-o',
  'building' = 'building',
  'bullhorn' = 'bullhorn',
  'bullseye' = 'bullseye',
  'bus' = 'bus',
  'buysellads' = 'buysellads',
  'calculator' = 'calculator',
  'calendar-check-o' = 'calendar-check-o',
  'calendar-minus-o' = 'calendar-minus-o',
  'calendar-o' = 'calendar-o',
  'calendar-plus-o' = 'calendar-plus-o',
  'calendar' = 'calendar',
  'calendar-times-o' = 'calendar-times-o',
  'camera-retro' = 'camera-retro',
  'camera' = 'camera',
  'caret-down' = 'caret-down',
  'caret-left' = 'caret-left',
  'caret-right' = 'caret-right',
  'caret-square-o-down' = 'caret-square-o-down',
  'caret-square-o-left' = 'caret-square-o-left',
  'caret-square-o-right' = 'caret-square-o-right',
  'caret-square-o-up' = 'caret-square-o-up',
  'caret-up' = 'caret-up',
  'car' = 'car',
  'cart-arrow-down' = 'cart-arrow-down',
  'cart-plus' = 'cart-plus',
  'cc-amex' = 'cc-amex',
  'cc-diners-club' = 'cc-diners-club',
  'cc-discover' = 'cc-discover',
  'cc-jcb' = 'cc-jcb',
  'cc-mastercard' = 'cc-mastercard',
  'cc-paypal' = 'cc-paypal',
  'cc-stripe' = 'cc-stripe',
  'cc' = 'cc',
  'cc-visa' = 'cc-visa',
  'certificate' = 'certificate',
  'chain-broken' = 'chain-broken',
  'check-circle-o' = 'check-circle-o',
  'check-circle' = 'check-circle',
  'check-square-o' = 'check-square-o',
  'check-square' = 'check-square',
  'check' = 'check',
  'chevron-circle-down' = 'chevron-circle-down',
  'chevron-circle-left' = 'chevron-circle-left',
  'chevron-circle-right' = 'chevron-circle-right',
  'chevron-circle-up' = 'chevron-circle-up',
  'chevron-down' = 'chevron-down',
  'chevron-left' = 'chevron-left',
  'chevron-right' = 'chevron-right',
  'chevron-up' = 'chevron-up',
  'child' = 'child',
  'chrome' = 'chrome',
  'circle-o-notch' = 'circle-o-notch',
  'circle-o' = 'circle-o',
  'circle' = 'circle',
  'circle-thin' = 'circle-thin',
  'classicpress-circle' = 'classicpress-circle',
  'classicpress' = 'classicpress',
  'clipboard' = 'clipboard',
  'clock-o' = 'clock-o',
  'clone' = 'clone',
  'cloud-download' = 'cloud-download',
  'cloud' = 'cloud',
  'cloud-upload' = 'cloud-upload',
  'code-fork' = 'code-fork',
  'codepen' = 'codepen',
  'code' = 'code',
  'codiepie' = 'codiepie',
  'coffee' = 'coffee',
  'cogs' = 'cogs',
  'cog' = 'cog',
  'columns' = 'columns',
  'commenting-o' = 'commenting-o',
  'commenting' = 'commenting',
  'comment-o' = 'comment-o',
  'comments-o' = 'comments-o',
  'comments' = 'comments',
  'comment' = 'comment',
  'compass' = 'compass',
  'compress' = 'compress',
  'connectdevelop' = 'connectdevelop',
  'contao' = 'contao',
  'copyright' = 'copyright',
  'creative-commons' = 'creative-commons',
  'credit-card-alt' = 'credit-card-alt',
  'credit-card' = 'credit-card',
  'crop' = 'crop',
  'crosshairs' = 'crosshairs',
  'css3' = 'css3',
  'c' = 'c',
  'cubes' = 'cubes',
  'cube' = 'cube',
  'cutlery' = 'cutlery',
  'dashcube' = 'dashcube',
  'database' = 'database',
  'deaf' = 'deaf',
  'debian' = 'debian',
  'delicious' = 'delicious',
  'desktop' = 'desktop',
  'deviantart' = 'deviantart',
  'dev-to' = 'dev-to',
  'diamond' = 'diamond',
  'diaspora' = 'diaspora',
  'digg' = 'digg',
  'digitalocean' = 'digitalocean',
  'discord-alt' = 'discord-alt',
  'discord' = 'discord',
  'dogmazic' = 'dogmazic',
  'dot-circle-o' = 'dot-circle-o',
  'download' = 'download',
  'dribbble' = 'dribbble',
  'dropbox' = 'dropbox',
  'drupal' = 'drupal',
  'edge' = 'edge',
  'eercast' = 'eercast',
  'eject' = 'eject',
  'ellipsis-h' = 'ellipsis-h',
  'ellipsis-v' = 'ellipsis-v',
  'emby' = 'emby',
  'empire' = 'empire',
  'envelope-open-o' = 'envelope-open-o',
  'envelope-open' = 'envelope-open',
  'envelope-o' = 'envelope-o',
  'envelope-square' = 'envelope-square',
  'envelope' = 'envelope',
  'envira' = 'envira',
  'eraser' = 'eraser',
  'ethereum' = 'ethereum',
  'etsy' = 'etsy',
  'eur' = 'eur',
  'exchange' = 'exchange',
  'exclamation-circle' = 'exclamation-circle',
  'exclamation' = 'exclamation',
  'exclamation-triangle' = 'exclamation-triangle',
  'expand' = 'expand',
  'expeditedssl' = 'expeditedssl',
  'external-link-square' = 'external-link-square',
  'external-link' = 'external-link',
  'eyedropper' = 'eyedropper',
  'eye-slash' = 'eye-slash',
  'eye' = 'eye',
  'facebook-messenger' = 'facebook-messenger',
  'facebook-official' = 'facebook-official',
  'facebook-square' = 'facebook-square',
  'facebook' = 'facebook',
  'fast-backward' = 'fast-backward',
  'fast-forward' = 'fast-forward',
  'fax' = 'fax',
  'f-droid' = 'f-droid',
  'female' = 'female',
  'ffmpeg' = 'ffmpeg',
  'fighter-jet' = 'fighter-jet',
  'file-archive-o' = 'file-archive-o',
  'file-audio-o' = 'file-audio-o',
  'file-code-o' = 'file-code-o',
  'file-epub' = 'file-epub',
  'file-excel-o' = 'file-excel-o',
  'file-image-o' = 'file-image-o',
  'file-o' = 'file-o',
  'file-pdf-o' = 'file-pdf-o',
  'file-powerpoint-o' = 'file-powerpoint-o',
  'files-o' = 'files-o',
  'file' = 'file',
  'file-text-o' = 'file-text-o',
  'file-text' = 'file-text',
  'file-video-o' = 'file-video-o',
  'file-word-o' = 'file-word-o',
  'film' = 'film',
  'filter' = 'filter',
  'fire-extinguisher' = 'fire-extinguisher',
  'firefox' = 'firefox',
  'fire' = 'fire',
  'first-order' = 'first-order',
  'flag-checkered' = 'flag-checkered',
  'flag-o' = 'flag-o',
  'flag' = 'flag',
  'flask' = 'flask',
  'flickr' = 'flickr',
  'floppy-o' = 'floppy-o',
  'folder-open-o' = 'folder-open-o',
  'folder-open' = 'folder-open',
  'folder-o' = 'folder-o',
  'folder' = 'folder',
  'font-awesome' = 'font-awesome',
  'fonticons' = 'fonticons',
  'font' = 'font',
  'fork-awesome' = 'fork-awesome',
  'fort-awesome' = 'fort-awesome',
  'forumbee' = 'forumbee',
  'forward' = 'forward',
  'foursquare' = 'foursquare',
  'free-code-camp' = 'free-code-camp',
  'freedombox' = 'freedombox',
  'friendica' = 'friendica',
  'frown-o' = 'frown-o',
  'funkwhale' = 'funkwhale',
  'futbol-o' = 'futbol-o',
  'gamepad' = 'gamepad',
  'gavel' = 'gavel',
  'gbp' = 'gbp',
  'genderless' = 'genderless',
  'get-pocket' = 'get-pocket',
  'gg-circle' = 'gg-circle',
  'gg' = 'gg',
  'gift' = 'gift',
  'gimp' = 'gimp',
  'gitea' = 'gitea',
  'github-alt' = 'github-alt',
  'github-square' = 'github-square',
  'github' = 'github',
  'gitlab' = 'gitlab',
  'git-square' = 'git-square',
  'git' = 'git',
  'glass' = 'glass',
  'glide-g' = 'glide-g',
  'glide' = 'glide',
  'globe-e' = 'globe-e',
  'globe' = 'globe',
  'globe-w' = 'globe-w',
  'gnupg' = 'gnupg',
  'gnu-social' = 'gnu-social',
  'google-plus-official' = 'google-plus-official',
  'google-plus-square' = 'google-plus-square',
  'google-plus' = 'google-plus',
  'google' = 'google',
  'google-wallet' = 'google-wallet',
  'graduation-cap' = 'graduation-cap',
  'gratipay' = 'gratipay',
  'grav' = 'grav',
  'hackaday' = 'hackaday',
  'hacker-news' = 'hacker-news',
  'hackster' = 'hackster',
  'hal' = 'hal',
  'hand-lizard-o' = 'hand-lizard-o',
  'hand-o-down' = 'hand-o-down',
  'hand-o-left' = 'hand-o-left',
  'hand-o-right' = 'hand-o-right',
  'hand-o-up' = 'hand-o-up',
  'hand-paper-o' = 'hand-paper-o',
  'hand-peace-o' = 'hand-peace-o',
  'hand-pointer-o' = 'hand-pointer-o',
  'hand-rock-o' = 'hand-rock-o',
  'hand-scissors-o' = 'hand-scissors-o',
  'handshake-o' = 'handshake-o',
  'hand-spock-o' = 'hand-spock-o',
  'hashnode' = 'hashnode',
  'hashtag' = 'hashtag',
  'hdd-o' = 'hdd-o',
  'header' = 'header',
  'headphones' = 'headphones',
  'heartbeat' = 'heartbeat',
  'heart-o' = 'heart-o',
  'heart' = 'heart',
  'history' = 'history',
  'home' = 'home',
  'hospital-o' = 'hospital-o',
  'hourglass-end' = 'hourglass-end',
  'hourglass-half' = 'hourglass-half',
  'hourglass-o' = 'hourglass-o',
  'hourglass-start' = 'hourglass-start',
  'hourglass' = 'hourglass',
  'houzz' = 'houzz',
  'h-square' = 'h-square',
  'html5' = 'html5',
  'hubzilla' = 'hubzilla',
  'i-cursor' = 'i-cursor',
  'id-badge' = 'id-badge',
  'id-card-o' = 'id-card-o',
  'id-card' = 'id-card',
  'ils' = 'ils',
  'imdb' = 'imdb',
  'inbox' = 'inbox',
  'indent' = 'indent',
  'industry' = 'industry',
  'info-circle' = 'info-circle',
  'info' = 'info',
  'inkscape' = 'inkscape',
  'inr' = 'inr',
  'instagram' = 'instagram',
  'internet-explorer' = 'internet-explorer',
  'ioxhost' = 'ioxhost',
  'italic' = 'italic',
  'jirafeau' = 'jirafeau',
  'joomla' = 'joomla',
  'joplin' = 'joplin',
  'jpy' = 'jpy',
  'jsfiddle' = 'jsfiddle',
  'julia' = 'julia',
  'jupyter' = 'jupyter',
  'keybase' = 'keybase',
  'keyboard-o' = 'keyboard-o',
  'key-modern' = 'key-modern',
  'key' = 'key',
  'krw' = 'krw',
  'language' = 'language',
  'laptop' = 'laptop',
  'laravel' = 'laravel',
  'lastfm-square' = 'lastfm-square',
  'lastfm' = 'lastfm',
  'leaf' = 'leaf',
  'leanpub' = 'leanpub',
  'lemon-o' = 'lemon-o',
  'level-down' = 'level-down',
  'level-up' = 'level-up',
  'liberapay-square' = 'liberapay-square',
  'liberapay' = 'liberapay',
  'life-ring' = 'life-ring',
  'lightbulb-o' = 'lightbulb-o',
  'line-chart' = 'line-chart',
  'linkedin-square' = 'linkedin-square',
  'linkedin' = 'linkedin',
  'link' = 'link',
  'linode' = 'linode',
  'linux' = 'linux',
  'list-alt' = 'list-alt',
  'list-ol' = 'list-ol',
  'list' = 'list',
  'list-ul' = 'list-ul',
  'location-arrow' = 'location-arrow',
  'lock' = 'lock',
  'long-arrow-down' = 'long-arrow-down',
  'long-arrow-left' = 'long-arrow-left',
  'long-arrow-right' = 'long-arrow-right',
  'long-arrow-up' = 'long-arrow-up',
  'low-vision' = 'low-vision',
  'magic' = 'magic',
  'magnet' = 'magnet',
  'male' = 'male',
  'map-marker' = 'map-marker',
  'map-o' = 'map-o',
  'map-pin' = 'map-pin',
  'map-signs' = 'map-signs',
  'map' = 'map',
  'mars-double' = 'mars-double',
  'mars-stroke-h' = 'mars-stroke-h',
  'mars-stroke' = 'mars-stroke',
  'mars-stroke-v' = 'mars-stroke-v',
  'mars' = 'mars',
  'mastodon-alt' = 'mastodon-alt',
  'mastodon-square' = 'mastodon-square',
  'mastodon' = 'mastodon',
  'matrix-org' = 'matrix-org',
  'maxcdn' = 'maxcdn',
  'meanpath' = 'meanpath',
  'medium-square' = 'medium-square',
  'medium' = 'medium',
  'medkit' = 'medkit',
  'meetup' = 'meetup',
  'meh-o' = 'meh-o',
  'mercury' = 'mercury',
  'microchip' = 'microchip',
  'microphone-slash' = 'microphone-slash',
  'microphone' = 'microphone',
  'minus-circle' = 'minus-circle',
  'minus-square-o' = 'minus-square-o',
  'minus-square' = 'minus-square',
  'minus' = 'minus',
  'mixcloud' = 'mixcloud',
  'mobile' = 'mobile',
  'modx' = 'modx',
  'money' = 'money',
  'moon-o' = 'moon-o',
  'moon' = 'moon',
  'motorcycle' = 'motorcycle',
  'mouse-pointer' = 'mouse-pointer',
  'music' = 'music',
  'neuter' = 'neuter',
  'newspaper-o' = 'newspaper-o',
  'nextcloud-square' = 'nextcloud-square',
  'nextcloud' = 'nextcloud',
  'nodejs' = 'nodejs',
  'object-group' = 'object-group',
  'object-ungroup' = 'object-ungroup',
  'odnoklassniki-square' = 'odnoklassniki-square',
  'odnoklassniki' = 'odnoklassniki',
  'opencart' = 'opencart',
  'open-collective' = 'open-collective',
  'openid' = 'openid',
  'opera' = 'opera',
  'optin-monster' = 'optin-monster',
  'orcid' = 'orcid',
  'outdent' = 'outdent',
  'pagelines' = 'pagelines',
  'paint-brush' = 'paint-brush',
  'paperclip' = 'paperclip',
  'paper-plane-o' = 'paper-plane-o',
  'paper-plane' = 'paper-plane',
  'paragraph' = 'paragraph',
  'patreon' = 'patreon',
  'pause-circle-o' = 'pause-circle-o',
  'pause-circle' = 'pause-circle',
  'pause' = 'pause',
  'paw' = 'paw',
  'paypal' = 'paypal',
  'peertube' = 'peertube',
  'pencil-square-o' = 'pencil-square-o',
  'pencil-square' = 'pencil-square',
  'pencil' = 'pencil',
  'percent' = 'percent',
  'phone-square' = 'phone-square',
  'phone' = 'phone',
  'php' = 'php',
  'picture-o' = 'picture-o',
  'pie-chart' = 'pie-chart',
  'pinterest-p' = 'pinterest-p',
  'pinterest-square' = 'pinterest-square',
  'pinterest' = 'pinterest',
  'pixelfed' = 'pixelfed',
  'plane' = 'plane',
  'play-circle-o' = 'play-circle-o',
  'play-circle' = 'play-circle',
  'play' = 'play',
  'pleroma' = 'pleroma',
  'plug' = 'plug',
  'plus-circle' = 'plus-circle',
  'plus-square-o' = 'plus-square-o',
  'plus-square' = 'plus-square',
  'plus' = 'plus',
  'podcast' = 'podcast',
  'power-off' = 'power-off',
  'print' = 'print',
  'product-hunt' = 'product-hunt',
  'puzzle-piece' = 'puzzle-piece',
  'python' = 'python',
  'qq' = 'qq',
  'qrcode' = 'qrcode',
  'question-circle-o' = 'question-circle-o',
  'question-circle' = 'question-circle',
  'question' = 'question',
  'quora' = 'quora',
  'quote-left' = 'quote-left',
  'quote-right' = 'quote-right',
  'random' = 'random',
  'ravelry' = 'ravelry',
  'react' = 'react',
  'rebel' = 'rebel',
  'recycle' = 'recycle',
  'reddit-alien' = 'reddit-alien',
  'reddit-square' = 'reddit-square',
  'reddit' = 'reddit',
  'refresh' = 'refresh',
  'registered' = 'registered',
  'renren' = 'renren',
  'repeat' = 'repeat',
  'reply-all' = 'reply-all',
  'reply' = 'reply',
  'researchgate' = 'researchgate',
  'retweet' = 'retweet',
  'road' = 'road',
  'rocket' = 'rocket',
  'rss-square' = 'rss-square',
  'rss' = 'rss',
  'rub' = 'rub',
  'safari' = 'safari',
  'scissors' = 'scissors',
  'scribd' = 'scribd',
  'scuttlebutt' = 'scuttlebutt',
  'search-minus' = 'search-minus',
  'search-plus' = 'search-plus',
  'search' = 'search',
  'sellsy' = 'sellsy',
  'server' = 'server',
  'shaarli-o' = 'shaarli-o',
  'shaarli' = 'shaarli',
  'share-alt-square' = 'share-alt-square',
  'share-alt' = 'share-alt',
  'share-square-o' = 'share-square-o',
  'share-square' = 'share-square',
  'share' = 'share',
  'shield' = 'shield',
  'ship' = 'ship',
  'shirtsinbulk' = 'shirtsinbulk',
  'shopping-bag' = 'shopping-bag',
  'shopping-basket' = 'shopping-basket',
  'shopping-cart' = 'shopping-cart',
  'shower' = 'shower',
  'signalapp' = 'signalapp',
  'signal' = 'signal',
  'sign-in' = 'sign-in',
  'sign-language' = 'sign-language',
  'sign-out' = 'sign-out',
  'simplybuilt' = 'simplybuilt',
  'sitemap' = 'sitemap',
  'skyatlas' = 'skyatlas',
  'skype' = 'skype',
  'slack' = 'slack',
  'sliders' = 'sliders',
  'slideshare' = 'slideshare',
  'smile-o' = 'smile-o',
  'snapchat-ghost' = 'snapchat-ghost',
  'snapchat-square' = 'snapchat-square',
  'snapchat' = 'snapchat',
  'snowdrift' = 'snowdrift',
  'snowflake-o' = 'snowflake-o',
  'social-home' = 'social-home',
  'sort-alpha-asc' = 'sort-alpha-asc',
  'sort-alpha-desc' = 'sort-alpha-desc',
  'sort-amount-asc' = 'sort-amount-asc',
  'sort-amount-desc' = 'sort-amount-desc',
  'sort-asc' = 'sort-asc',
  'sort-desc' = 'sort-desc',
  'sort-numeric-asc' = 'sort-numeric-asc',
  'sort-numeric-desc' = 'sort-numeric-desc',
  'sort' = 'sort',
  'soundcloud' = 'soundcloud',
  'space-shuttle' = 'space-shuttle',
  'spell-check' = 'spell-check',
  'spinner' = 'spinner',
  'spoon' = 'spoon',
  'spotify' = 'spotify',
  'square-o' = 'square-o',
  'square' = 'square',
  'stack-exchange' = 'stack-exchange',
  'stack-overflow' = 'stack-overflow',
  'star-half-o' = 'star-half-o',
  'star-half' = 'star-half',
  'star-o' = 'star-o',
  'star' = 'star',
  'steam-square' = 'steam-square',
  'steam' = 'steam',
  'step-backward' = 'step-backward',
  'step-forward' = 'step-forward',
  'stethoscope' = 'stethoscope',
  'sticky-note-o' = 'sticky-note-o',
  'sticky-note' = 'sticky-note',
  'stop-circle-o' = 'stop-circle-o',
  'stop-circle' = 'stop-circle',
  'stop' = 'stop',
  'street-view' = 'street-view',
  'strikethrough' = 'strikethrough',
  'stumbleupon-circle' = 'stumbleupon-circle',
  'stumbleupon' = 'stumbleupon',
  'subscript' = 'subscript',
  'subway' = 'subway',
  'suitcase' = 'suitcase',
  'sun-o' = 'sun-o',
  'sun' = 'sun',
  'superpowers' = 'superpowers',
  'superscript' = 'superscript',
  'syncthing' = 'syncthing',
  'table' = 'table',
  'tablet' = 'tablet',
  'tachometer' = 'tachometer',
  'tags' = 'tags',
  'tag' = 'tag',
  'tasks' = 'tasks',
  'taxi' = 'taxi',
  'telegram' = 'telegram',
  'television' = 'television',
  'tencent-weibo' = 'tencent-weibo',
  'terminal' = 'terminal',
  'text-height' = 'text-height',
  'text-width' = 'text-width',
  'themeisle' = 'themeisle',
  'thermometer-empty' = 'thermometer-empty',
  'thermometer-full' = 'thermometer-full',
  'thermometer-half' = 'thermometer-half',
  'thermometer-quarter' = 'thermometer-quarter',
  'thermometer-three-quarters' = 'thermometer-three-quarters',
  'th-large' = 'th-large',
  'th-list' = 'th-list',
  'th' = 'th',
  'thumbs-down' = 'thumbs-down',
  'thumbs-o-down' = 'thumbs-o-down',
  'thumbs-o-up' = 'thumbs-o-up',
  'thumbs-up' = 'thumbs-up',
  'thumb-tack' = 'thumb-tack',
  'ticket' = 'ticket',
  'times-circle-o' = 'times-circle-o',
  'times-circle' = 'times-circle',
  'times' = 'times',
  'tint' = 'tint',
  'tipeee' = 'tipeee',
  'toggle-off' = 'toggle-off',
  'toggle-on' = 'toggle-on',
  'tor-onion' = 'tor-onion',
  'trademark' = 'trademark',
  'train' = 'train',
  'transgender-alt' = 'transgender-alt',
  'transgender' = 'transgender',
  'trash-o' = 'trash-o',
  'trash' = 'trash',
  'tree' = 'tree',
  'trello' = 'trello',
  'tripadvisor' = 'tripadvisor',
  'trophy' = 'trophy',
  'truck' = 'truck',
  'try' = 'try',
  'tty' = 'tty',
  'tumblr-square' = 'tumblr-square',
  'tumblr' = 'tumblr',
  'twitch' = 'twitch',
  'twitter-square' = 'twitter-square',
  'twitter' = 'twitter',
  'umbrella' = 'umbrella',
  'underline' = 'underline',
  'undo' = 'undo',
  'universal-access' = 'universal-access',
  'university' = 'university',
  'unlock-alt' = 'unlock-alt',
  'unlock' = 'unlock',
  'unslpash' = 'unslpash',
  'upload' = 'upload',
  'usb' = 'usb',
  'usd' = 'usd',
  'user-circle-o' = 'user-circle-o',
  'user-circle' = 'user-circle',
  'user-md' = 'user-md',
  'user-o' = 'user-o',
  'user-plus' = 'user-plus',
  'user-secret' = 'user-secret',
  'users' = 'users',
  'user' = 'user',
  'user-times' = 'user-times',
  'venus-double' = 'venus-double',
  'venus-mars' = 'venus-mars',
  'venus' = 'venus',
  'viacoin' = 'viacoin',
  'viadeo-square' = 'viadeo-square',
  'viadeo' = 'viadeo',
  'video-camera' = 'video-camera',
  'vimeo-square' = 'vimeo-square',
  'vimeo' = 'vimeo',
  'vine' = 'vine',
  'vk' = 'vk',
  'volume-control-phone' = 'volume-control-phone',
  'volume-down' = 'volume-down',
  'volume-mute' = 'volume-mute',
  'volume-off' = 'volume-off',
  'volume-up' = 'volume-up',
  'weibo' = 'weibo',
  'weixin' = 'weixin',
  'whatsapp' = 'whatsapp',
  'wheelchair-alt' = 'wheelchair-alt',
  'wheelchair' = 'wheelchair',
  'wifi' = 'wifi',
  'wikidata' = 'wikidata',
  'wikipedia-w' = 'wikipedia-w',
  'window-close-o' = 'window-close-o',
  'window-close' = 'window-close',
  'window-maximize' = 'window-maximize',
  'window-minimize' = 'window-minimize',
  'window-restore' = 'window-restore',
  'windows' = 'windows',
  'wire' = 'wire',
  'wordpress' = 'wordpress',
  'wpbeginner' = 'wpbeginner',
  'wpexplorer' = 'wpexplorer',
  'wpforms' = 'wpforms',
  'wrench' = 'wrench',
  'xing-square' = 'xing-square',
  'xing' = 'xing',
  'xmpp' = 'xmpp',
  'yahoo' = 'yahoo',
  'y-combinator' = 'y-combinator',
  'yelp' = 'yelp',
  'yoast' = 'yoast',
  'youtube-play' = 'youtube-play',
  'youtube-square' = 'youtube-square',
  'youtube' = 'youtube',
  'zotero' = 'zotero'
}
