"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.waitRequestTransaction = exports.waitTransaction = void 0;
function waitTransaction(call) {
    return __awaiter(this, void 0, void 0, function* () {
        const tx = yield call;
        yield tx.wait();
    });
}
exports.waitTransaction = waitTransaction;
/**
 * Creates a new transaction or waits the pending one from the request object.
 * @param provider RPC provider
 * @param request a request object
 * @param sendTx function that creates a new transaction if needed
 * @param timeout how much time the function will wait until throws a timeout error
 */
function waitRequestTransaction(provider, request, sendTx, timeout = 30000) {
    return __awaiter(this, void 0, void 0, function* () {
        if (!request.pendingTx) {
            request.pendingTx = yield sendTx();
        }
        const tx = request.pendingTx;
        yield new Promise((resolve, reject) => __awaiter(this, void 0, void 0, function* () {
            const timeoutHandle = setTimeout(() => {
                reject(new Error('Transaction timeout'));
            }, timeout);
            try {
                yield provider.waitForTransaction(tx.hash);
                resolve();
            }
            catch (error) {
                reject(error);
                request.pendingTx = undefined;
            }
            finally {
                clearTimeout(timeoutHandle);
            }
        }));
        request.completedTxs.push(request.pendingTx);
        request.pendingTx = undefined;
    });
}
exports.waitRequestTransaction = waitRequestTransaction;
