"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getDappRegistryEnvironmentConfig = exports.getEnsEnvironmentConfig = exports.DAPP_REGISTRY_ENVIRONMENT_CONFIGS = exports.ENS_ENVIRONMENT_CONFIGS = void 0;
const environments_enum_1 = require("../model/environments.enum");
exports.ENS_ENVIRONMENT_CONFIGS = {
    [environments_enum_1.Environments.LOCALHOST]: {
        rpcUrl: 'http://127.0.0.1:9545/',
        contractAddresses: {
            ensRegistry: process.env.DOCKER_ENS_REGISTRY_ADDRESS,
            fdsRegistrar: process.env.DOCKER_FDS_REGISTRAR_ADDRESS,
            publicResolver: process.env.DOCKER_PUBLIC_RESOLVER_ADDRESS,
        },
        performChecks: false,
    },
    [environments_enum_1.Environments.GOERLI]: {
        rpcUrl: 'https://xdai.dev.fairdatasociety.org/',
        contractAddresses: {
            ensRegistry: process.env.GOERLI_ENS_REGISTRY_ADDRESS,
            fdsRegistrar: process.env.GOERLI_FDS_REGISTRAR_ADDRESS,
            publicResolver: process.env.GOERLI_PUBLIC_RESOLVER_ADDRESS,
        },
        performChecks: false,
    },
    [environments_enum_1.Environments.SEPOLIA]: {
        rpcUrl: 'https://rpc.sepolia.org/',
        contractAddresses: {
            ensRegistry: process.env.SEPOLIA_ENS_REGISTRY_ADDRESS,
            fdsRegistrar: process.env.SEPOLIA_FDS_REGISTRAR_ADDRESS,
            publicResolver: process.env.SEPOLIA_PUBLIC_RESOLVER_ADDRESS,
        },
        performChecks: false,
    },
};
exports.DAPP_REGISTRY_ENVIRONMENT_CONFIGS = {
    [environments_enum_1.Environments.LOCALHOST]: {
        rpcUrl: 'http://127.0.0.1:9545/',
        dappRegistryAddress: process.env.DOCKER_DAPP_REGISTRY_ADDRESS,
        ratingsAddress: process.env.DOCKER_RATINGS_ADDRESS,
    },
    [environments_enum_1.Environments.GOERLI]: {
        rpcUrl: 'https://xdai.dev.fairdatasociety.org/',
        dappRegistryAddress: process.env.GOERLI_DAPP_REGISTRY_ADDRESS,
        ratingsAddress: process.env.GOERLI_RATINGS_ADDRESS,
    },
    [environments_enum_1.Environments.SEPOLIA]: {
        rpcUrl: 'https://rpc.sepolia.org/',
        dappRegistryAddress: process.env.SEPOLIA_DAPP_REGISTRY_ADDRESS,
        ratingsAddress: process.env.SEPOLIA_RATINGS_ADDRESS,
    },
};
function getEnsEnvironmentConfig(environment) {
    return exports.ENS_ENVIRONMENT_CONFIGS[environment];
}
exports.getEnsEnvironmentConfig = getEnsEnvironmentConfig;
function getDappRegistryEnvironmentConfig(environment) {
    return exports.DAPP_REGISTRY_ENVIRONMENT_CONFIGS[environment];
}
exports.getDappRegistryEnvironmentConfig = getDappRegistryEnvironmentConfig;
