"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.assertUsername = exports.isUsername = void 0;
const assert_1 = require("./assert");
function isUsername(username) {
    if (!username || typeof username !== 'string')
        return false;
    const pattern = /^[a-z0-9_-]*$/;
    const matches = username.match(pattern);
    const pattern2 = /^[A-Z]*$/;
    const matches2 = username.match(pattern2);
    return (username.length < 83 && username.length > 3 && matches !== null && matches.length > 0 && matches2 === null);
}
exports.isUsername = isUsername;
function assertUsername(username) {
    (0, assert_1.assert)(isUsername(username), 'Username is not valid.');
}
exports.assertUsername = assertUsername;
